// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the theme object.
$derenda-app-theme: mat.define-theme((
    color: (
        theme-type: light,
        primary: mat.$azure-palette,
        tertiary: mat.$blue-palette,
    ),
    density: (
        scale: 0,
    )
));

@include mat.color-variants-backwards-compatibility($derenda-app-theme);

@mixin theme($derenda-app-theme) {
    --mat-toolbar-container-background-color: #{mat.get-theme-color($derenda-app-theme, primary, 40)};
    --mat-toolbar-container-text-color: #{mat.get-theme-color($derenda-app-theme, primary, 100)};
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
:root {
    @include mat.all-component-themes($derenda-app-theme);
    @include mat.all-component-typographies($derenda-app-theme);
    @include theme($derenda-app-theme);
}

// Comment out the line below if you want to use the pre-defined typography utility classes.
// For more information: https://material.angular.io/guide/typography#using-typography-styles-in-your-application.
// @include mat.typography-hierarchy($derenda-app-theme);

// Comment out the line below if you want to use the deprecated `color` inputs.
// @include mat.color-variants-backwards-compatibility($derenda-app-theme);
/* You can add global styles to this file, and also import other style files */

@for $i from 0 through 24 {
    .mat-mdc-card.mat-elevation-z#{$i} {
        @include mat.elevation($i);
    }
}

.full-width {
    width: 100%;
}

.half-width {
    width: 50%;
}

.three-quarters-width {
    width: 75%;
}

.toolbar-spacer {
    flex: 1 1 auto;
}

.mat-list-item-name {
    display: flex;
    align-items: center;

    &__icon {
        margin-right: 0.5rem;
    }
}

.sidenav-footer {
    position: fixed;
    bottom: 0;
    margin-left: 4rem;
}

.mat-mdc-snack-bar-container {
    &.snackbar-error {
        --mdc-snackbar-container-color: #dc3545;
        --mat-mdc-snack-bar-button-color: #fff;
        --mdc-snackbar-supporting-text-color: #fff;
    }

    &.snackbar-success {
        --mdc-snackbar-container-color: #198754;
        --mat-mdc-snack-bar-button-color: #fff;
        --mdc-snackbar-supporting-text-color: #fff;
    }

    &.snackbar-warning {
        --mdc-snackbar-container-color: #FFC106;
        --mat-mdc-snack-bar-button-color: #fff;
        --mdc-snackbar-supporting-text-color: #fff;
    }
}

.wrapper-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
}
